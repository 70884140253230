import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const EftposCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = ({ getStarted }) => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Platform
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Gifting Solutions
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Waivpay is the largest supplier of Gifting Solutions to independent shopping centres in 
        Australia. Managing over 165 shopping centres (growing daily) which service 20,000 +
        individual retailers. Our suite of solutions includes our shopping centre app that allows your
        centre to have a branded app and branded digital card, a 24/7 gift card kiosk, a concierge for the
        customer service desk, and a Webstore for online purchases; Waivpay has you covered. 
      </p>
      <p className="mt-4 text-lg text-gray-500">
        We support both Mastercard for digital cards and EFTPOS for physical cards. Of course, all funds
        are held in trust to protect your customers and supported by an Australian customer service
        team that has you covered while your centres are open.
      </p>
      {
        getStarted &&
          <div className="mt-6">
            <StartButton to="/giftcards" />
          </div>
      }
    </div>
  </div>
);

export default EftposCards;
